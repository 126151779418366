<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="150px" :inline="true">
                <el-form-item label="设置状态启用：" prop="country">
                    <el-select v-model="dataForm.enableType" style="width: 310px">
                        <el-option v-for="item in statusOption" :key="item.value" :label="item.name" :value="item.value"/>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary">设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';
import jsonSchema from '@/api/jsonschema/json-schema';
import {queryEsaioManagerJsonSchemaDetail} from '@/api/jsonschema/jsonSchemaApi';

export default {

    name: 'some-status-enable',
    data() {

        return {

            command: {

                commandCodeR: 'get_flag_status',
                commandCodeW: 'set_some_status_enable'
            },
            deviceSn: '',
            dataForm: {

                enableType: ''
            },
            statusOption: [],
            jsonSchemaData: {}
        }
    },
    methods: {

        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('某些状态启用指令读取中...', 30);
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        this.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
            this.queryWriteCommandJsonSchema();
        },
        initSuccessCallBack(data) {

            // 属性对应的中文名称
            let flagStatusNameRel = {

                buzzer: '蜂鸣器',
                overloadBypass: '过载旁路',
                powerSaving: '省电',
                lcdDisplay: 'LCD显示屏在超时1分钟后跳转到默认页面',
                overloadRestart: '过载重启',
                overTemperatureRestart: '过温重启',
                backlightOn: '背光打开',
                alarmInterrupt: '主源中断时报警',
                faultCodeRecord: '故障代码记录',
            }
            // 属性对应的指令码
            let flagStatusValueRel = {

                buzzer: 'A',
                overloadBypass: 'B',
                powerSaving: 'J',
                lcdDisplay: 'K',
                overloadRestart: 'U',
                overTemperatureRestart: 'V',
                backlightOn: 'X',
                alarmInterrupt: 'Y',
                faultCodeRecord: 'Z',
            }
            this.dataForm.enableType = '';
            this.statusOption = [];
            for (let key in data) {

                if (data[key] === "0") {

                    this.statusOption.push({

                        name: flagStatusNameRel[key],
                        value: flagStatusValueRel[key]
                    });
                }
            }
        },
        queryWriteCommandJsonSchema() {

            let param = {

                deviceSn: this.deviceSn,
                commandCode: this.command.commandCodeW
            };
            queryEsaioManagerJsonSchemaDetail(param).then((res) => {

                if (res.code === global.response_status_success_obj) {

                    this.jsonSchemaData = res.data;
                } else {

                    message.error(res.msg);
                }
            }).catch(err => {

                message.error('请求失败,请稍后再试');
                console.error(err);
            });
        },
        submit() {

            let check = jsonSchema.validate(this.jsonSchemaData,  this.dataForm);
            if (!check.pass) {

                message.error('参数错误');
                return;
            }
            this.cmdLoading = loading.loading('设置某些状态启用指令下发中...', 30);
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command.commandCodeW,
                    data: this.dataForm
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        let enableType = this.dataForm.enableType;
                        let index = this.statusOption.findIndex(option => option.value === enableType);
                        if (index !== -1) {

                            this.statusOption.splice(index, 1);
                        }
                        this.dataForm.enableType = '';
                        message.success('设置某些状态启用指令下发成功');
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            setTimeout(() => {

                mqttService.disconnectMqtt();
            }, 30000);
            mqttService.sendEsaioBusinessCommand(option);
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    margin: 10px 0 1% 5%
}

.btn-box {

    text-align: center;
}
</style>
